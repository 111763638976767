import React from 'react'
import Ls1 from '../../assets/images/ls1.png';

const ConsultingTraining = () => {
  return (
    <section className="container__services__details">
      <h2 className="text-title">
        SERVICES
      </h2>
      <div className="wrapper__image">
        <div>
          <h1>
            CONSULTING AND TRAINING
          </h1>
          <p>
            At DO Framing Layout, we combine the implementation of advanced digital design and construction methodologies with specialized training in construction technology to maximize efficiency and accuracy in construction projects.
            <br /><br />
            <b>
              Implementation of Digital Design and Construction Methodologies
            </b>
            <br /><br />
            We offer consulting and advisory services for the implementation of advanced methodologies such as Building Information Modeling (BIM) and Virtual Design and Construction (VDC). <br /><br />
            These methodologies enhance efficiency in design and construction processes, provide greater precision in project planning and execution, reduce errors and rework, and optimize resource utilization.
          </p>
        </div>
        <div>
          <img
            alt="CONSULTING AND TRAINING"
            src={Ls1}
          />
        </div>
      </div>
      <div className="wrapper__text__services">
        <p>
          <b>
            Specialized Training in Construction Technology <br/><br/>
          </b>
          We provide specialized training programs in the use of advanced construction technologies, including BIM, VDC, project management, and specialized software.
          Our training courses enable workers to acquire fundamental technical and technological skills to enhance their performance, increase productivity, and adapt to the latest industry trends. <br/><br/><br/>
          <b>
            Merging Methodologies and Training <br/><br/>
          </b>
          The combination of our implementation of advanced methodologies and specialized training ensures that not only are the best practices in digital design and construction adopted, but teams are also equipped to use them effectively. This results in a comprehensive improvement of construction processes, from planning to execution, ensuring more efficient, precise projects with reduced margin for error.
          At DO Framing Layout, we focus on providing both the tools and the necessary knowledge for construction professionals to excel in their projects, optimizing each phase of the process and guaranteeing high-quality results.
        </p>
      </div>
    </section>
  )
}

export default ConsultingTraining
