import React from 'react';
import { motion } from 'framer-motion';

const ServiceCard = ({ imageUrl, title, description, link, inView }) => {
  return (
    <motion.div
      className="service-card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.5 }}
    >
      <img src={imageUrl} alt={title} />
      <h2>{title}</h2>
      <p>{description}</p>
      <a href={link} className="btn__link">Read more</a>
    </motion.div>
  );
};

export default ServiceCard;
