import ImgService1 from '../assets/images/s1.png'
import ImgService2 from '../assets/images/s2.png'
import ImgService3 from '../assets/images/s3.png'
//Definimos los datos para las cards de servicios
const servicesData = [
  {
    id: 1,
    title: 'CONSULTING AND TRAINING',
    description: 'At DO Framing Layout, we combine the implementation of advanced digital design and construction methodologies with specialized.',
    imageUrl: ImgService1,
    link: '/services/consulting-and-training'
  },
  {
    id: 2,
    title: 'DIGITAL PRODUCTS',
    description: 'Every digital product developed at DO Framing Layout serves a specific purpose to enhance coordination and efficiency throughout.',
    imageUrl: ImgService2,
    link: '/services/digital-products'
  },
  {
    id: 3,
    title: 'IMPLEMENTATION OF EFFECTIVE CONSTRUCTION PROCESSES',
    description: 'At DO Framing Layout, our services are focused on ensuring detailed planning before commencing any construction project through thorough pre-construction analysis.',
    imageUrl: ImgService3,
    link: '/services/implementation-of-efective-construction'
  },
];

export default servicesData;