import React from 'react'
import Ls21 from '../../assets/images/ls2.png';
import Ls22 from '../../assets/images/ls2.3.png';
import Ls23 from '../../assets/images/ls3.4.png';
import Ls24 from '../../assets/images/s221.png';
import Ls25 from '../../assets/images/s222.png';

const DigitalProducts = () => {
    return (
        <section className="container__services__details bg__main">
            <h2 className="text-title">
                SERVICES
            </h2>
            <div className="wrapper__image">
                <div>
                    <img
                        src={Ls21}
                        alt="CONSULTING AND TRAINING"
                    />
                </div>
                <div>
                    <h1>
                        DIGITAL PRODUCTS
                    </h1>
                    <p>
                        Every digital product developed at DO Framing Layout serves a specific purpose to enhance coordination and efficiency throughout construction projects:
                        <br /><br />
                        <b>
                            Design of projects using Building Information Modeling (BIM) or relevant software
                        </b>
                        <br /><br />
                        This involves creating detailed digital models of the project using BIM software tailored to the specific needs of each construction venture. BIM facilitates comprehensive visualization and planning, integrating various aspects of the project such as architectural, structural, and MEP (Mechanical, Electrical, Plumbing) systems. It enables virtual construction before physical work begins, ensuring better coordination and reducing errors during execution.
                    </p>
                </div>
            </div>
            <div className="wrapper__text__services">
                <p>
                    <b>
                        Digital plan design in DO Framing Layout includes several types of essential technical documentation for construction, each crucial to ensure accuracy and efficiency on site <br /><br />
                    </b>
                </p>
                <div className="grid__services">
                    <p>
                        1. Anchor Bolts (AB) and Holdown Layout:
                        These designs detail the location and specifications of the anchors and restraint systems used in the structure. It is essential to ensure that structural elements are properly secured and meet the required standards of safety and strength.
                        <br /><br />
                        2. Detail Sheets:
                        Detail sheets precisely specify construction elements such as connections, joints, and specific material details. These documents are crucial for contractors and workers to understand exactly how to execute each aspect of the design, avoiding misinterpretations that could lead to costly errors.
                        <br /><br />
                        3. Cut Sheets:
                        These sheets provide detailed instructions for the precise cutting of materials such as wood, metal, or other construction materials. They include precise measurements, angles, and fitting details, ensuring that materials are prepared correctly for installation. This is crucial for avoiding waste and optimizing resources.
                        <br /><br />
                        4. Beam and Floor Layout:
                        These designs specify the dimensions, materials, and construction methods for beams and floors. They detail how these components should be fabricated, assembled, and installed, ensuring that the structure meets load requirements and structural safety standards.
                    </p>
                    <div>
                        <img
                            alt='service'
                            src={Ls22}
                        />
                        <img
                            src={Ls23}
                            alt='service'
                        />
                    </div>
                </div>
            </div>
            <div className="wrapper__bg__beige">
                <h4>
                    Importance and Benefits
                </h4>
                <p>
                    - Accuracy and Clarity: They provide clear and detailed instructions that reduce the likelihood of errors during construction. <br />
                    - Efficiency: They minimize the need for rework by ensuring that workers have precise information from the outset. <br />
                    - Safety: They ensure that all structural elements and construction details are executed correctly, enhancing the safety and durability of the construction.
                </p>
            </div>
            <div className="wrapper__text__servives">
                <p>
                    Collectively, these digital designs not only facilitate efficient and safe construction but also optimize the overall process by providing clear and precise guidance to all involved in the construction project. <br /><br />
                </p>
                <div className="grid__services">
                    <div>
                        <p>
                            <b>
                                Prefabricated Panel Design
                            </b>
                        </p>
                        <p>
                            Using 3D modeling, we digitally design prefabricated panels and structures. This approach allows for detailed visualization and planning of prefabricated components, optimizing manufacturing and assembly processes on-site. Prefabrication reduces construction time, minimizes waste, and improves construction quality. <br /><br />
                        </p>
                        <p>
                            <b>
                                Design of Digital Sheets for Coordination with Other Specialties
                            </b>
                        </p>
                        
                        <p>
                            These sheets enable seamless coordination among different specialized teams involved in the construction process, such as architects, engineers, and subcontractors. They ensure that all parties are aligned with project requirements and specifications, fostering collaboration and reducing conflicts.
                        </p>
                       
                        <p>
                            <b>
                                Design of Digital Sheets for RFI Workshops
                            </b>
                        </p>
                        
                        <p>
                            Digitally designed Request for Information (RFI) sheets facilitate effective communication among project stakeholders. They streamline the process of clarifying project details, resolving issues, and requesting necessary information, preventing delays and ensuring smooth project progress.
                            <br /><br />
                            In summary, these digital products developed at DO Framing Layout play a crucial role in facilitating effective coordination and improving efficiency throughout the construction lifecycle. They enable precise planning, clear communication, and smooth execution, ultimately contributing to the successful completion of construction projects with reduced costs and timelines.
                        </p>
                    </div>
                    <div>
                        <img
                            alt='service'
                            src={Ls24}
                        />
                        <img
                            src={Ls25}
                            alt='service'
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DigitalProducts
