import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ImageAbout from '../assets/images/ab1.png';
import ImageAbout2 from '../assets/images/ab2.png';
const AboutUs = () => {
  const { ref: imageRef, inView: imageInView } = useInView({ threshold: 0.5, triggerOnce: true });
  const { ref: textRef, inView: textInView } = useInView({ threshold: 0.5, triggerOnce: true });
  const { ref: detailedTextRef, inView: detailedTextInView } = useInView({ threshold: 0.5 });

  return (
    <main>
      <section className="page__about__us">
        <motion.div ref={imageRef}
          initial={{ opacity: 0, y: 50 }}
          animate={imageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.8, ease: "easeOut" }}>
          <img src={ImageAbout} alt="About Us" />
          <img
            src={ImageAbout2}
            alt="About US"
          />
        </motion.div>
        <motion.div ref={textRef}
          initial={{ opacity: 0, y: 50 }}
          animate={textInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          className="about-us-content">
          <h2 className='text-title'>ABOUT US</h2>
          <p>
            DO Framing Layout is dedicated to optimizing the efficiency of construction companies in the United States through sustainability criteria and a focus on value. We evaluate and improve the information systems used in all project phases and train workers, construction companies, students, and recent graduates interested in the construction industry. Our goal is to drive growth in the U.S. construction sector through more effective decision-making based on Virtual Design and Construction (VDC) methodologies and Building Information Modeling (BIM).
            <br/><br/>
            Our mission is to transform the construction industry in the United States by optimizing time and resources through proper analysis before and during construction. We are committed to providing innovative digital products that prevent errors and rework, improving coordination among all essential specialties for the correct execution of projects. We also seek to establish a digital communication link between design and construction areas, enhancing the efficiency of construction processes in the short, medium, and long term.
            <br/><br/>
            We aspire to be the benchmark in the construction industry in the United States for implementing advanced digital design and construction methodologies. We promote the adoption of advanced technologies and foster a culture of continuous improvement in technical, administrative, and digital processes within the industry. We aim to be leaders in the positive transformation of construction projects nationwide.
          </p>
        </motion.div>
      </section>
      <section className="page__about__us__text">
        <motion.div ref={detailedTextRef}
          initial={{ opacity: 0 }}
          animate={detailedTextInView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.8 }}>
          <h2>
            The Importance of Preconstruction
          </h2>
          <p>
          At DO Framing Layout, we believe that the preconstruction phase is crucial for the success of any project. A thorough and detailed analysis during this phase allows for the identification of necessary resources, definition of project scope, establishment of clear objectives, and consideration of sustainable measures from the outset. This not only optimizes resources and time but also prevents errors and rework, ensuring a smoother and more efficient project execution. Proper planning and the implementation of advanced technologies in preconstruction create a solid foundation for the long-term success of each project, fostering effective collaboration among all parties involved.
          </p>
        </motion.div>
      </section>
    </main>
  );
};

export default AboutUs;
