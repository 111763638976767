import React from 'react';
import { motion } from 'framer-motion';

const ProjectCard = ({ imageUrl, link, inView }) => {
  return (
    <motion.div
      className="project-card"
      style={{ backgroundImage: `url(${imageUrl})` }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="project-overlay">
      </div>
    </motion.div>
  );
};

export default ProjectCard;
