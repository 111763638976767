import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Footer from './components/Footer';
// Subpáginas
import ConsultingTraining from './pages/services/ConsultingTraining';
import DigitalProducts from './pages/services/DigitalProducts';
import Implementation from './pages/services/ImplementationOfEfective';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/consulting-and-training" element={<ConsultingTraining />} />
        <Route path="/services/digital-products" element={<DigitalProducts />} />
        <Route path="/services/implementation-of-efective-construction" element={<Implementation />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
