import React from 'react'
import IGLogo from '../assets/images/IG_Logo.png';

const Footer = () => {
  return (
    <footer>
      <div>
        <a href='#politic'>
          PRIVACY POLICY
        </a>
      </div>
      <div>
        <p>
          © 2024 ALL RIGHTS RESERVED DO FRAMING LAYOUT LLC.
        </p>
      </div>
      <div className="ig__footer">
        <img
          alt='Logo insta'
          src={IGLogo}
        />
        <a href='https://www.instagram.com/do.framing/'>
          do.framing
        </a>
      </div>
    </footer>
  )
}

export default Footer
