import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from '../assets/images/logo.png';
import LogoWhite from '../assets/images/logo_white.png';

const Header = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname === path;
  };

  // Determina dinámicamente el título de la página según la ruta
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Home - DO Framing Layout';
      case '/services':
      case '/services/consulting-and-training':
      case '/services/digital-products':
        return 'Services - DO Framing Layout';
      case '/about-us':
        return 'About Us - DO Framing Layout';
      case '/projects':
        return 'Projects - DO Framing Layout';
      case '/contact':
        return 'Contact - DO Framing Layout';
      default:
        return 'DO Framing Layout'; // Título predeterminado
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={location.pathname === '/' ? 'home-header' : ''}>
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>
      <div className="header-inner">
        <div className="logo">
          <Link to="/">
            {location.pathname !== '/' && (
              <img src={location.pathname === '/' ? Logo : LogoWhite} alt="Logo" />
            )}
          </Link>
        </div>
        <nav className={menuOpen ? 'active' : ''}>
          <Link to="/services/consulting-and-training" className={`nav-link ${isActive('/services/consulting-and-training') ? 'active-link' : ''}`}>Services</Link>
          <Link to="/about-us" className={`nav-link ${isActive('/about-us') ? 'active-link' : ''}`}>About us</Link>
          <Link to="/contact" className={`nav-link ${isActive('/contact') ? 'active-link' : ''}`}>Contact</Link>
        </nav>
      </div>
      <div className="header-inner-mobil">
        <div>
          <div className="logo">
            <Link to="/">
              {location.pathname !== '/' && (
                <img src={location.pathname === '/' ? Logo : LogoWhite} alt="Logo" />
              )}
            </Link>
          </div>
          <span className={`menu-button ${location.pathname === '/' ? 'menu-black' : ''}`}
            onClick={toggleMenu}>
            {menuOpen ? '\u2715' : '\u2630'} {/* Cambia entre "☰" y "✕" */}
          </span>
        </div>
        <nav className={menuOpen ? 'active' : ''}>
          <Link to="/services/consulting-and-training" className={`nav-link ${isActive('/services/consulting-and-training') ? 'active-link' : ''}`}>Services</Link>
          <Link to="/about-us" className={`nav-link ${isActive('/about-us') ? 'active-link' : ''}`}>About us</Link>
          <Link to="/contact" className={`nav-link ${isActive('/contact') ? 'active-link' : ''}`}>Contact</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
