import React from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ServiceCard from '../components/ServiceCard';
import ProjectCard from '../components/ProjectCard';
import servicesData from '../data/serviceData';
import projectData from '../data/projectData';
import Logo from '../assets/images/logo.png';
import HeroImg1 from '../assets/images/hero1.png';
import HeroImg2 from '../assets/images/hero2.png';
import HeroImg3 from '../assets/images/hero3.png';
import About from '../assets/images/aboutus.png';


const Home = () => {
    const { ref: heroRef, inView: heroInView } = useInView({ threshold: 0.5 });
    const { ref: servicesRef, inView: servicesInView } = useInView({ threshold: 0.3 });
    const { ref: projectsRef, inView: projectsInView } = useInView({ threshold: 0.5 });
    const { ref: captionRef, inView: captionInView } = useInView({ threshold: 0.5 });
    const { ref: aboutUsRef, inView: aboutUsInView } = useInView({ threshold: 0.5 });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const timeoutRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const handlePrev = useCallback(() => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? projectData.length - 1 : prevIndex - 1
        );
    }, [isTransitioning]);

    const handleNext = useCallback(() => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) =>
            prevIndex === projectData.length - 1 ? 0 : prevIndex + 1
        );
    }, [isTransitioning]);

    useEffect(() => {
        if (projectsInView) {
            resetTimeout();
            timeoutRef.current = setTimeout(handleNext, 3000);
        }

        return () => {
            resetTimeout();
        };
    }, [currentIndex, projectsInView, handleNext]);

    useEffect(() => {
        if (isTransitioning) {
            const transitionTimeout = setTimeout(() => {
                setIsTransitioning(false);
                if (currentIndex === projectData.length) {
                    setCurrentIndex(0);
                } else if (currentIndex < 0) {
                    setCurrentIndex(projectData.length - 1);
                }
            }, 500); // Debe coincidir con la duración de la transición CSS

            return () => clearTimeout(transitionTimeout);
        }
    }, [currentIndex, isTransitioning]);

    return (
        <main>
            <section className="hero" ref={heroRef}>
                <div className="container__hero">
                    <motion.div
                        initial={{ opacity: 0, x: -10 }}
                        animate={heroInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -10 }}
                        transition={{ duration: 0.8 }}
                        className="hero-content"
                    >
                        <img src={Logo} alt="Logo" className="logo_hero" />
                        <h1>
                            Specialists in the implementation of Design, Construction and Digital Products Methodologies
                        </h1>
                        <p>"Architectural structures are also in evolution."</p>
                        <a href="/about-us" className="btn__hero">Read more</a>
                    </motion.div>
                    <div className="container__hero__images angry-grid">
                        <motion.div
                            id="item-0"
                            initial={{ opacity: 0, y: 50 }}
                            animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            <img src={HeroImg3} alt="Hero 2" />
                        </motion.div>
                        <motion.div
                            id="item-1"
                            initial={{ opacity: 0, y: 50 }}
                            animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                        >
                            <img src={HeroImg1} alt="Hero 1" />
                        </motion.div>
                        <motion.div
                            id="item-2"
                            initial={{ opacity: 0, y: 50 }}
                            animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
                        >
                            <img src={HeroImg2} alt="Hero 3" />
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="services" ref={servicesRef}>
                <h2 className="text-title">SERVICES</h2>
                <div className="container__services">
                    {servicesData.map((service) => (
                        <ServiceCard
                            key={service.id}
                            {...service}
                            inView={servicesInView}
                        />
                    ))}
                </div>
            </section>
            <section className="caption" ref={captionRef}>
                <div className="container__caption">
                    <motion.h2
                        initial={{ opacity: 0 }}
                        animate={captionInView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        Increase the efficiency of your <br></br> project with preconstruction services
                    </motion.h2>
                </div>
            </section>
            <section className="about_us" ref={aboutUsRef}>
                <div className="image-container">
                    <motion.img
                        src={About}
                        alt="About us"
                        className="image__about__us"
                        initial={{ opacity: 0, x: -20 }}
                        animate={aboutUsInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                        transition={{ duration: 0.8 }}
                    />
                </div>
                <div className="content-container">
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={aboutUsInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
                        transition={{ duration: 0.8 }}
                    >
                        <h2 className="text-title">ABOUT US</h2>
                        <p>
                            DO Framing Layout is dedicated to optimizing the efficiency of construction companies in the United States through sustainability criteria and a focus on value. We evaluate and improve the information systems used in all project phases and train workers, construction companies, students, and recent graduates interested in the construction industry. Our goal is to drive growth in the U.S. construction sector through more effective decision-making based on Virtual Design and Construction (VDC) methodologies and Building Information Modeling (BIM).
                        </p>
                        <a href="/about-us" className="btn__link">Read more</a>
                    </motion.div>
                </div>
            </section>
            <section className="projects" ref={projectsRef}>
                <div className="title__projects">
                    <h2 className="text-title">PROJECTS</h2>
                </div>
                <div className="container__projects">
                    <div
                        className="carousel-track"
                        style={{
                            transform: isMobile
                                ? `translateX(${-currentIndex * 100}%)`
                                : `translateX(${-currentIndex * 33.33}%)`,
                            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                        }}
                    >
                        {[...projectData, ...projectData, ...projectData].map((project, index) => (
                            <ProjectCard
                                key={index} // Usamos index aquí ya que estamos duplicando elementos
                                imageUrl={project.imageUrl}
                                link={project.link}
                                inView={projectsInView}
                            />
                        ))}
                    </div>
                    <button className="carousel-control prev" onClick={handlePrev}>
                        &#10094;
                    </button>
                    <button className="carousel-control next" onClick={handleNext}>
                        &#10095;
                    </button>
                </div>
            </section>
        </main>
    );
}

export default Home;
