import React from 'react'
import Ls1 from '../../assets/images/implementatio.png';

const Implementation = () => {
  return (
    <section className="container__services__details">
      <h2 className="text-title">
        SERVICES
      </h2>
      <div className="wrapper__image">
        <div>
          <h1>
            IMPLEMENTATION OF EFFECTIVE AND SUSTAINABLE CONSTRUCTION PROCESSES
          </h1>
          <p>
          At DO Framing Layout, our services are focused on ensuring detailed planning before commencing any construction project thorough pre-construction analysis.
            <br /><br />
            <b className='primary-font'>
            Evaluation of Design Strategies and Construction Processes
            </b>
            <br /><br/>
            We carefully analyze and evaluate design strategies and construction processes to identify best practices and methods that ensure project efficiency and quality.
            <br/><br/><br/>
            <b className='primary-font'>
            Creation of Digital Communication Links
            </b>
            <br/><br/><br/>
            We establish effective digital communication channels to facilitate coordination and collaboration among all teams involved in the project, from architects and engineers to subcontractors and suppliers.
          </p>
        </div>
        <div>
          <img
            alt="CONSULTING AND TRAINING"
            src={Ls1}
          />
        </div>
      </div>
      <div className="wrapper__text__services">
        <p>
          <b className='primary-font' style={{ fontWeight: 'bold' }}>
          Selection of Innovative Digital Products <br/><br/>
          </b>
          We choose and utilize advanced digital products that optimize every phase of the project, from design and planning to execution and monitoring.
        </p>
      </div>
      <div className="wrapper__text__services__bg__white">
        <div>
          <h3>
          Benefits
          </h3>
          <ul>
            <li>
            Efficient and Sustainable Planning: Our comprehensive approach ensures that every aspect of the project is well-planned, promoting sustainability and efficiency from the outset.
            </li>
            <li>
            eduction of Errors and Rework: Thorough evaluation and implementation of advanced technologies help minimize errors and rework, saving valuable time and resources.
            </li>
            <li>
            Enhanced Coordination and Collaboration among Teams: Effective digital communication and seamless collaboration among teams ensure that all stakeholders are aligned with project goals and requirements.
            </li>
            <li>
            Resource Optimization: The use of advanced methodologies and innovative digital products enables better management and utilization of available resources, optimizing costs and delivery times.
            </li>
          </ul>
        </div>
      </div>
      <div className="wrapper__text__services">
        <p>
        At DO Framing Layout, our services are designed to provide a solid and well-planned foundation for any construction project, ensuring its success through meticulous preparation and efficient execution.
        </p>
      </div>
    </section>
  )
}

export default Implementation
