import React from 'react'
import { useState } from 'react';

const Contact = () => {

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }; 

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    service: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill in all required fields.');
      return;
    }

    // Validación de correo electrónico
    if (!validateEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    console.log(formData);
    // Implementa la lógica de validación según tus requerimientos

    try {
      const response = await fetch('https://formspree.io/f/mnqeoyaj', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Your message has been sent successfully!');
        setFormData({ name: '', company: '', email: '', service: '', message: '' }); // Resetear el estado del formulario
        console.log(response);
      } else {
        alert('There was a problem sending your message.');
      }
    } catch (error) {
      console.error('There was an error submitting the form:', error);
    }
  };

  return (
    <main>
      <section className="page__contact">
        <div className="page__contact__title">
          <h2>
            Contact
          </h2>
        </div>
        <div className="page__contact__form">
          <div className="contact__details">
            <a
              href="tel:619 721 6104"
            >
              619 721 6104
            </a>
            <a
              href="mailto:doframinglayout@gmail.com"
            >
              doframinglayout@gmail.com
            </a>
            <a
              href="#ig"
            >
              DO FRAMING LAYOUT LLC.
            </a>
          </div>
          <form id="contactForm" onSubmit={handleSubmit}>
            <input
              name="name"
              value={formData.name}
              placeholder="Name:"
              onChange={handleChange}
              type="text"
              required
            />
            <input
              name="company"
              value={formData.company}
              placeholder="Company:"
              onChange={handleChange}
              type="text"
            />
            <input
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleChange}
              type="email"
              required
            />
            <input
              name="service"
              value={formData.service}
              placeholder="Desired service:"
              onChange={handleChange}
              type="text"
            />
            <textarea
              name="message"
              value={formData.message}
              placeholder="Message"
              onChange={handleChange}
              required
            />
            <button type="submit" className="btn__link">
              Send
            </button>
          </form>
        </div>
      </section>
    </main>
  )
}

export default Contact
