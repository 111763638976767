import image1 from '../assets/images/p1.png';
import image2 from '../assets/images/p2.png';
import image3 from '../assets/images/p3.png';
import image4 from '../assets/images/p4.png';
import image5 from '../assets/images/p5.png';
import image6 from '../assets/images/p6.png';
import image7 from '../assets/images/p7.png';

const projectData = [
    {
      id: 1,
      imageUrl: image1,
      link: '#'
    },
    {
      id: 2,
      imageUrl: image2,
      link: '#'
    },
    {
      id: 3,
      imageUrl: image3,
      link: '#'
    },
    {
      id: 4,
      imageUrl: image7,
      link: '#'
    },
    {
      id: 5,
      imageUrl: image4,
      link: '#'
    },
    {
      id: 6,
      imageUrl: image5,
      link: '#'
    },
    {
      id: 7,
      imageUrl: image6,
      link: '#'
    },
  ];
  
  export default projectData;